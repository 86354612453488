/** @jsx jsx */
import { jsx, Heading, Text, Box } from "theme-ui"

import Layout from "../components/Layout"

const ShippingPage = () => {
  return (
    <Layout>
      <Box bg="#fff">
        <Box
          mx="auto"
          py={[60, 60, 100]}
          px={[20, 20, 40]}
          sx={{ maxWidth: 650 }}
        >
          <Heading sx={{ mb: 4 }}>Shipping</Heading>

          <Text variant="subtitle">Delivery</Text>
          <Text variant="paragraph">
            At adammonster.com we ship nationwide in Australia. We also ship
            also can ship internationally. Purchased items will be packed and
            shipped within 1 business day.
          </Text>

          <Text variant="paragraph">
            Australian orders should be received between 1 and 5 business days
            depending on Australia Post.
          </Text>

          <Text variant="paragraph">
            Allow for 6 or more business days for International orders to be
            delivered. If for any reason you have not received your order within
            the time frame please don’t hesitate to email us directly at{" "}
            <a href="mailto:help@adammonster.com">help@adammonster.com</a>
          </Text>

          <Text variant="subtitle">Delivery cost</Text>
          <Text variant="paragraph">
            Costs vary based on the shipping location and the weight of your
            order. To work out your shipping cost online simply add the items
            you wish to order to your cart, fill out your shipping address, then
            see your total and delivery cost at checkout.
          </Text>

          <Text variant="subtitle">Tracking</Text>
          <Text variant="paragraph">
            Your unique tracking number will be emailed to you with your
            shipping confirmation direct to your email address. You will be able
            to input your tracking number via the Australia Post web interface
            to find the whereabouts of your order.
          </Text>

          <Text variant="subtitle">International</Text>
          <Text variant="paragraph">
            All Adam Monster orders are shipped via Australia Post.
            International customers may be responsible for customs duties and /
            or brokerage fees in addition to shipping costs. Feel free to
            contact us directly at{" "}
            <a href="mailto:help@adammonster.com">help@adammonster.com</a> if
            you require any more information regarding shipping.
          </Text>
        </Box>
      </Box>
    </Layout>
  )
}

export default ShippingPage
